<template>
  <div ref="spreadContainer" class="spread-container">
  </div>
</template>

<script>
const fileSaver = require('file-saver')

export default {
  name: 'SpreadJs',
  props: {
    spreadInfo: {
      type: Object,
      default () {
        return {}
      }
    },
    defaultDataList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      index: 0,
      headerRows: [],
      dataColumns: [],
      errors: {},
      currentErrorIndex: 0
    }
  },
  computed: {
    errorKeys () {
      return Object.keys(this.errors).sort()
    }
  },
  methods: {
    initWorkbook (el) {
      // 初始化spread，目前只开一个sheet页
      this.spread = new GC.Spread.Sheets.Workbook(el, { calcOnDemand: true, sheetCount: 0 })
      // 设置右键菜单不可以使用
      this.spread.options.allowContextMenu = false
      // 设置右键菜单可用项目
      this.spread.contextMenu.menuData = this.spreadMenus
      // 设置不可粘贴excel样式
      this.spread.options.allowCopyPasteExcelStyle = false
      // 设置不可新增sheet页
      this.spread.options.newTabVisible = false

      this.spread.commandManager().setShortcutKey('clearAndEditing', GC.Spread.Commands.Key.del, false, false, false, false)

      // 初始化sheets页面
      this.initWorksheets()
    },
    initWorksheets () {
      // 添加worksheet页
      this.worksheet = new GC.Spread.Sheets.Worksheet(this.spreadInfo.sheetName)
      this.spread.addSheet(0, this.worksheet)

      // 设置剪贴板模式
      this.worksheet.options.clipBoardOptions = 1

      // 绑定样式
      this.bindStyles()

      // 初始化列头
      this.initWorksheetHeader()

      // 设置保护
      this.setProtection()

      // 绑定默认数据
      if (this.defaultDataList.length) {
        this.bindDefaultData()
        this.mergeCell()
      }

      // 绑定事件
      this.bindEvents()
    },
    initWorksheetHeader () {
      this.spread.suspendPaint()

      if (this.spreadInfo.columns && this.spreadInfo.columns.length) {
        this.index = 0
        this.parseColumns(null, this.spreadInfo.columns, 0)
        this.index = 0

        // 计算rowSpan和colSpan
        for (let i = this.headerRows.length - 1; i > -1; --i) {
          let row = this.headerRows[i]
          row.forEach(column => {
            if (column.children && column.children.length) {
              column.colSpan = column.children.reduce((total, item) => total + item.colSpan, 0)
              column.index = column.children[0].index
            } else {
              column.colSpan = 1
            }
          })
        }
        for (let i = 0; i < this.headerRows.length; ++i) {
          let row = this.headerRows[i]
          row.forEach(column => {
            if (column.children && column.children.length) {
              column.rowSpan = 1
            } else {
              column.rowSpan = this.headerRows.length - i
            }
          })
        }

        // 设置头部行数
        this.worksheet.setRowCount(this.headerRows.length, GC.Spread.Sheets.SheetArea.colHeader)
        // 设置列数
        this.worksheet.setColumnCount(this.dataColumns.length, GC.Spread.Sheets.SheetArea.colHeader)
        // 设置头部具体信息
        for (let i = 0; i < this.headerRows.length; ++i) {
          let row = this.headerRows[i]
          row.forEach(column => {
            this.worksheet.addSpan(i, column.index, column.rowSpan, column.colSpan, GC.Spread.Sheets.SheetArea.colHeader)
            this.worksheet.setValue(i, column.index, column.label, GC.Spread.Sheets.SheetArea.colHeader)
            this.worksheet.setColumnWidth(column.index, column.width)
          })
        }
      }

      this.spread.resumePaint()
    },
    parseColumns (parent, columns, level) {
      if (!this.headerRows[level]) {
        this.headerRows[level] = []
      }
      this.headerRows[level] = this.headerRows[level].concat(columns)
      columns.forEach(column => {
        column.parent = parent
        if (column.children && column.children.length) {
          this.parseColumns(column, column.children, level + 1)
        } else {
          column.index = this.index++
          this.dataColumns.push(column)
        }
      })
    },
    setProtection () {
      const option = {
        allowSelectLockedCells: true,
        allowSelectUnlockedCells: true,
        allowFilter: true,
        allowSort: false,
        allowResizeRows: true,
        allowResizeColumns: true,
        allowEditObjects: false,
        allowDragInsertRows: false,
        allowDragInsertColumns: false,
        allowInsertRows: false,
        allowInsertColumns: false,
        allowDeleteRows: false,
        allowDeleteColumns: false,
        allowOutlineColumns: false,
        allowOutlineRows: false
      }
      this.worksheet.options.protectionOptions = option
      this.worksheet.options.isProtected = true

      this.dataColumns.forEach(column => {
        if (column.editable) {
          this.worksheet.setStyleName(-1, column.index, 'unLockStyle')
        }
      })
    },
    bindDefaultData () {
      let dataArray = []
      this.defaultDataList.forEach(item => {
        let dataItem = []
        this.dataColumns.forEach(column => {
          dataItem.push(item[column.dataKey] ? item[column.dataKey] : '')
        })
        dataArray.push(dataItem)
      })
      this.worksheet.setRowCount(this.defaultDataList.length)
      this.worksheet.setArray(0, 0, dataArray)
    },
    bindEvents () {
      // 绑定valueChanged事件
      this.worksheet.bind(GC.Spread.Sheets.Events.EditEnded, (sender, info) => {
        // if (isNaN(info.oldValue) && isNaN(info.newValue)) {
        //   this.setValue(info.row, info.col, '')
        // }
        // this.rowCompute(info.row)
        this.$emit('value-changed', info.row, this.dataColumns[info.col].dataKey)
      })

      // 绑定拖拽事件
      this.worksheet.bind(GC.Spread.Sheets.Events.DragFillBlockCompleted, (e, info) => {
        if (info.fillRange.colCount === 1) {
          for (let i = 0; i < info.fillRange.rowCount; ++i) {
            // this.rowCompute(info.fillRange.row + i)
            this.$emit('value-changed', info.fillRange.row + i, this.dataColumns[info.fillRange.col].dataKey)
          }
        }
      })

      // 绑定粘贴事件
      this.worksheet.bind(GC.Spread.Sheets.Events.ClipboardPasted, (sender, info) => {
        if (info.cellRange.colCount === 1) {
          for (let i = 0; i < info.cellRange.rowCount; ++i) {
            this.$emit('value-changed', info.cellRange.row + i, this.dataColumns[info.cellRange.col].dataKey)
          }
        }
      })
    },
    rowCompute (rowIndex) {
      this.dataColumns.filter(column => column.formula).forEach((column, index) => {
        let row = this.getRow(rowIndex)
        this.validateRow(rowIndex)
        let num = column.formula(row, this.defaultDataList)
        this.worksheet.setValue(rowIndex, column.index, num)
      })
    },
    getRow (rowIndex) {
      let row = {
        index: rowIndex
      }
      this.dataColumns.forEach(column => {
        row[column.dataKey] = this.worksheet.getValue(rowIndex, column.index)
      })
      return row
    },
    setRow (rowIndex, row) {
      this.dataColumns.forEach(column => {
        this.worksheet.setValue(rowIndex, column.index, row[column.dataKey])
      })
    },
    getValue (rowIndex, dataKey) {
      return this.worksheet.getValue(rowIndex, this.dataColumns.find(item => item.dataKey === dataKey).index)
    },
    setValue (rowIndex, dataKey, value) {
      let column = this.dataColumns.find(item => item.dataKey === dataKey)
      this.worksheet.setValue(rowIndex, column.index, value)
    },
    validate () {
      for (let i = 0; i < this.defaultDataList.length; ++i) {
        this.validateRow(i)
      }
    },
    validateRow (rowIndex) {
      let result = true
      this.dataColumns.filter(column => column.validator).forEach(column => {
        let row = this.getRow(rowIndex)
        if (!column.validator(row)) {
          this.setError(row.index, column.index)
          result = false
        } else {
          this.clearError(row.index, column.index)
        }
      })
      return result
    },
    setError (rowIndex, colIndex) {
      let errorKey = `${rowIndex}_${colIndex}`
      this.$set(this.errors, errorKey, {
        rowIndex,
        colIndex
      })
      setTimeout(() => {
        this.worksheet.setStyleName(rowIndex, colIndex, 'invalidStyle')
      }, 2)
    },
    clearError (rowIndex, colIndex) {
      let errorKey = `${rowIndex}_${colIndex}`
      this.$delete(this.errors, errorKey)
      setTimeout(() => {
        this.worksheet.setStyleName(rowIndex, colIndex, '')
      }, 2)
    },
    bindStyles () {
      let invalidStyle = new GC.Spread.Sheets.Style()
      invalidStyle.name = 'invalidStyle'
      invalidStyle.borderLeft = new GC.Spread.Sheets.LineBorder('red', GC.Spread.Sheets.LineStyle.thin)
      invalidStyle.borderRight = new GC.Spread.Sheets.LineBorder('red', GC.Spread.Sheets.LineStyle.thin)
      invalidStyle.borderTop = new GC.Spread.Sheets.LineBorder('red', GC.Spread.Sheets.LineStyle.thin)
      invalidStyle.borderBottom = new GC.Spread.Sheets.LineBorder('red', GC.Spread.Sheets.LineStyle.thin)
      invalidStyle.backColor = 'red'
      invalidStyle.foreColor = 'white'

      let unLockStyle = new GC.Spread.Sheets.Style()
      unLockStyle.name = 'unLockStyle'
      unLockStyle.borderLeft = new GC.Spread.Sheets.LineBorder('gray', GC.Spread.Sheets.LineStyle.thin)
      unLockStyle.borderRight = new GC.Spread.Sheets.LineBorder('gray', GC.Spread.Sheets.LineStyle.thin)
      unLockStyle.borderTop = new GC.Spread.Sheets.LineBorder('gray', GC.Spread.Sheets.LineStyle.thin)
      unLockStyle.borderBottom = new GC.Spread.Sheets.LineBorder('gray', GC.Spread.Sheets.LineStyle.thin)
      unLockStyle.locked = false

      this.spread.addNamedStyle(invalidStyle)
      this.spread.addNamedStyle(unLockStyle)
    },
    saveExcel (fileName) {
      const excelIo = new GC.Spread.Excel.IO()
      const json = this.spread.toJSON()
      excelIo.save(json, (blob) => {
        fileSaver.saveAs(blob, fileName)
      })
    },
    exportExcel (fileName) {
      const excelIo = new GC.Spread.Excel.IO()
      const json = this.spread.toJSON()
      const tempSpread = new GC.Spread.Sheets.Workbook()
      tempSpread.fromJSON(json)

      var hearderStyle = new GC.Spread.Sheets.Style()
      hearderStyle.borderLeft = new GC.Spread.Sheets.LineBorder('black', GC.Spread.Sheets.LineStyle.medium)
      hearderStyle.borderTop = new GC.Spread.Sheets.LineBorder('black', GC.Spread.Sheets.LineStyle.medium)
      hearderStyle.borderRight = new GC.Spread.Sheets.LineBorder('black', GC.Spread.Sheets.LineStyle.medium)
      hearderStyle.borderBottom = new GC.Spread.Sheets.LineBorder('black', GC.Spread.Sheets.LineStyle.medium)
      hearderStyle.hAlign = GC.Spread.Sheets.HorizontalAlign.center
      hearderStyle.vAlign = GC.Spread.Sheets.VerticalAlign.center
      for (var sheetPro in tempSpread.sheets) {
        var sheet = tempSpread.sheets[sheetPro]
        if (!(sheet instanceof GC.Spread.Sheets.Worksheet)) {
          continue
        }

        var headerRowCount = sheet.getRowCount(GC.Spread.Sheets.SheetArea.colHeader)
        var dataRowCount = sheet.getRowCount()
        var headerColCount = this.dataColumns.length
        if (headerRowCount > 1 || sheet.getText(0, 0, GC.Spread.Sheets.SheetArea.colHeader) !== 'A') {
          sheet.addRows(0, headerRowCount)
          for (var row = 0; row < headerRowCount; row++) {
            sheet.setStyle(row, -1, hearderStyle)
            for (var col = 0; col < sheet.getColumnCount(); col++) {
              sheet.setValue(row, col, sheet.getText(row, col, GC.Spread.Sheets.SheetArea.colHeader))
              var span = sheet.getSpan(row, col, GC.Spread.Sheets.SheetArea.colHeader)
              if (span && span.row === row && span.col === col && (span.rowCount > 1 || span.colCount > 1) && span.rowCount <= headerRowCount - span.row && span.colCount <= headerColCount - span.col) {
                sheet.addSpan(span.row, span.col, span.rowCount, span.colCount)
              }
            }
          }
          for (let row = headerRowCount; row < dataRowCount + headerRowCount; ++row) {
            sheet.setStyle(row, -1, hearderStyle)
          }
          sheet.frozenRowCount(headerRowCount)
        }
      }
      var newJSON = tempSpread.toJSON()
      excelIo.save(newJSON, (blob) => {
        fileSaver.saveAs(blob, fileName)
      })
    },
    getData () {
      let resultData = []
      for (let i = 0; i < this.defaultDataList.length; ++i) {
        let row = this.getRow(i)
        resultData.push(row)
      }
      return resultData
    },
    getActiveRowIndex () {
      return this.worksheet.getActiveRowIndex()
    },
    mergeCell () {
      const autoMergeColumns = this.dataColumns.filter(item => item.autoMerge)
      const rowCount = this.defaultDataList.length

      autoMergeColumns.forEach(column => {
        const style = new GC.Spread.Sheets.Style()
        style.hAlign = GC.Spread.Sheets.HorizontalAlign.center
        style.vAlign = GC.Spread.Sheets.VerticalAlign.center
        this.worksheet.setStyle(-1, column.index, style)

        const colIndex = column.index
        let currentCellValue = ''
        let startRowIndex = 0
        for (let rowIndex = 0; rowIndex < rowCount; ++rowIndex) {
          const cellValue = this.worksheet.getValue(rowIndex, colIndex)
          if (currentCellValue !== cellValue) {
            currentCellValue = cellValue
            if (rowIndex !== startRowIndex) {
              this.worksheet.addSpan(startRowIndex, colIndex, rowIndex - startRowIndex, 1)
              startRowIndex = rowIndex
            }
          }
        }
        this.worksheet.addSpan(startRowIndex, colIndex, rowCount - startRowIndex, 1)
      })
    },
    fitColumn () {
      this.worksheet.autoFitColumn()
    },
    frozenColumns (num) {
      this.worksheet.frozenColumnCount(num)
    }
  },
  mounted () {
    this.initWorkbook(this.$refs.spreadContainer)
  }
}
</script>

<style scoped lang="scss">
.spread-container {
  width: 100%;
  height: 100%;
}
</style>
